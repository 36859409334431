import { defineStore } from "pinia";

export const useCategoryStore = defineStore("categoryStore", {

  state: () => ({

    http: useNuxtApp().$http,

    query: {
      search: null,
      categoryIds: null,
      page: null,
      statuses: [1]
    },

    categories: {
      data: []
    }
  }),

  actions: {

    async getCategories() {

      const data = await this.http.index("product/category", this.query)

      this.categories = data.categories
    }

    //async store(params) {
  },
});

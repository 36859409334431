<template>
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <app-card>
      <v-card-title class="pa-4"> Categoria </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col>
            <AppTextField v-model="form.name" label="Nome" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <AppSwitch v-model="form.status" label="Ativo" color="primary" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end pa-4">
        <AppCancelBtn @click="dialog = false" />
        <AppConfirmBtn @click="handleStore()" />
      </v-card-actions>
    </app-card>
  </v-dialog>
</template>

<script setup>
const categoryForm = useCategoryFormStore();

const $loading = useLoading();

const emit = defineEmits(["store"]);

const dialog = ref(false);

const form = computed(() => categoryForm.form);

const open = async (id = null, params = null) => {
  
  categoryForm.$reset();

  if (id) {
    $loading.start();
    await categoryForm
      .load(id)
      .then((response) => $loading.stop())
      .catch((error) => $loading.stop());
  }

  if (params) {
    form.value.name = params.name;
  }

  dialog.value = true;
};

const handleStore = async () => {
  
  $loading.start();
  
  await categoryForm.store().then((response) => {
      
      emit("store", response);
      
      dialog.value = false;
      
      $loading.stop();
    })
    .catch((error) => {
      $loading.stop();
    });
};

defineExpose({
  open,
});
</script>

<style lang="scss" scoped></style>
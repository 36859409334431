
export default [
  { value: '00', title: "00 | Mercadoria para Revenda" },
  { value: '01', title: "01 | Matéria-Prima" },
  { value: '02', title: "02 | Embalagem" },
  { value: '03', title: "03 | Produto em Processo" },
  { value: '04', title: "04 | Produto Acabado" },
  { value: '05', title: "05 | Subproduto" },
  { value: '06', title: "06 | Produto Intermediário" },
  { value: '07', title: "07 | Material de Uso e Consumo" },
  { value: '08', title: "08 | Ativo Imobilizado" },
  { value: '09', title: "09 | Serviços" },
  { value: '10', title: "10 | Outros insumos" },
  { value: '99', title: "99 | Outras" },
]
